import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { openModal, closeModal } from 'shared/components/Modal/actions';
import httpWithLogging, {
  httpWithLoggingWithoutCustomHeaders,
} from 'universal/http-client';
import { types as spinnerActions } from 'shared/components/Spinner/spinner-actions';
import { amplifySignUp, amplifyLogin } from 'client-utils/utilities-amplify';
import { setKmsiStatus } from '@bgo-ui/common/client/utilities/utilities-kmsi';

import {
  defaultApiTimeout,
  resetPasswordError,
  registrationErrors,
} from './constants';
import { parseCopyData } from '../../utilities/copy-helpers';
import { updateAdditionalAccountInfo } from '../myaccount/pages/AccountDetailsPage/actions';

export const GO_TO_FORGOT_PASSWORD_STEP_2 = 'GO_TO_FORGOT_PASSWORD_STEP_2';
export const LOADING_FORGOT_PASSWORD = 'LOADING_FORGOT_PASSWORD';
export const RESOLVED_FORGOT_PASSWORD = 'RESOLVED_FORGOT_PASSWORD';
export const REJECTED_FORGOT_PASSWORD = 'REJECTED_FORGOT_PASSWORD';
export const LOADING_RESET_PASSWORD = 'LOADING_RESET_PASSWORD';
export const RESOLVED_RESET_PASSWORD = 'RESOLVED_RESET_PASSWORD';
export const REJECTED_RESET_PASSWORD = 'REJECTED_RESET_PASSWORD';
export const RESOLVED_LOGIN_PAGE = 'RESOLVED_LOGIN_PAGE';
export const RESOLVED_REGISTRATION_PAGE = 'RESOLVED_REGISTRATION_PAGE';
export const DT_LOGIN_FAILURE = 'DT_LOGIN_FAILURE';
export const LOADING_LOGIN_CONTENT = 'LOADING_LOGIN_CONTENT';
export const RESOLVED_LOGIN_CONTENT = 'RESOLVED_LOGIN_CONTENT';
export const REJECTED_LOGIN_CONTENT = 'REJECTED_LOGIN_CONTENT';
export const LOADING_REGISTRATION = 'LOADING_REGISTRATION';
export const RESOLVED_REGISTRATION = 'RESOLVED_REGISTRATION';
export const REJECTED_REGISTRATION = 'REJECTED_REGISTRATION';
export const RESOLVED_FORGOT_PASSWORD_PAGE = 'RESOLVED_FORGOT_PASSWORD_PAGE';
export const RESOLVED_CHECK_YOUR_EMAIL_PAGE = 'RESOLVED_CHECK_YOUR_EMAIL_PAGE';
export const RESOLVED_PASSWORD_RESET_NEW_PAGE =
  'RESOLVED_PASSWORD_RESET_NEW_PAGE';

/**
 * Call when a login attempt was not successful
 * @param isNewLayout {boolean} set true for new layout, false for old layout
 * @returns {function(*): *}
 */
export function loginFailureUtagData(isNewLayout) {
  return dispatch =>
    dispatch({
      type: DT_LOGIN_FAILURE,
      payload: {
        isNewLayout,
      },
    });
}

export const openForgotPasswordModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'ForgotPasswordModal',
        className: 'forgot-password-modal',
      }),
    );
};

export const openSignInModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'LoginModal',
        className: 'login-modal',
      }),
    );
};

export const openPhoneModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'PhoneModal',
        className: 'phone-modal',
      }),
    );
};
export const openPhoneModalCommPref = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'PhoneModalCommPref',
        className: 'phone-modal-comm-pref',
      }),
    );
};
export function goToForgotPasswordStep2() {
  return dispatch => dispatch({ type: GO_TO_FORGOT_PASSWORD_STEP_2 });
}

export function forgotPassword(data, isNewLayout = false) {
  return (dispatch, getState) => {
    const requestApi = httpWithLoggingWithoutCustomHeaders(
      getState(),
      defaultApiTimeout,
      true,
    );
    dispatch({ type: LOADING_FORGOT_PASSWORD, payload: { isNewLayout } });
    return requestApi.post(NMConfig.API_FORGOT_PASSWORD, data).then(
      () => {
        dispatch({ type: RESOLVED_FORGOT_PASSWORD });
        dispatch(closeModal());
      },
      () => dispatch({ type: REJECTED_FORGOT_PASSWORD }),
    );
  };
}

export function resetPassword(data, isNewLayout = false) {
  return (dispatch, getState) => {
    const requestApi = httpWithLoggingWithoutCustomHeaders(
      getState(),
      defaultApiTimeout,
      true,
    );

    dispatch({ type: LOADING_RESET_PASSWORD });
    dispatch({ type: spinnerActions.SHOW_SPINNER });
    return requestApi
      .post(NMConfig.API_RESET_PASSWORD, data)
      .then(
        () =>
          dispatch({ type: RESOLVED_RESET_PASSWORD, payload: { isNewLayout } }),
        error =>
          dispatch({
            type: REJECTED_RESET_PASSWORD,
            payload: get(error, 'response.data.message', resetPasswordError),
          }),
      )
      .finally(() => dispatch({ type: spinnerActions.HIDE_SPINNER }));
  };
}

export function getContentForLoginPage() {
  return (dispatch, getState) => {
    const state = getState();
    const content = get(state, 'login.loginContent', {});
    if (isEmpty(content)) {
      const requestApi = httpWithLogging(state, 8000);
      const callString = `${NMConfig.API_LOGIN_CONTENT}/desktop`;
      dispatch({ type: LOADING_LOGIN_CONTENT });
      return requestApi
        .get(callString, {})
        .then(res => {
          if (res && res.data) {
            dispatch({
              type: RESOLVED_LOGIN_CONTENT,
              payload: parseCopyData(res.data),
            });
          } else {
            dispatch({ type: REJECTED_LOGIN_CONTENT });
          }
        })
        .catch(() => dispatch({ type: REJECTED_LOGIN_CONTENT }));
    } else {
      return undefined;
    }
  };
}

const loginSuccessCallback = (dispatch, payload) => {
  const { month, day, mostlyInterestedIn } = payload;
  const valuesToUpdate = { mostlyInterestedIn };
  if (month && day) {
    valuesToUpdate['birthday'] = `${month} ${day}`;
  }
  const redirectLink = payload.redirectLink || '';
  const enableKmsi = payload.enableKmsi || false;
  const returnToUrl = new URLSearchParams(location.search).get('returnToURL');
  dispatch({ type: RESOLVED_REGISTRATION });
  setKmsiStatus(enableKmsi);
  dispatch(
    updateAdditionalAccountInfo(valuesToUpdate, {
      redirect: () => {
        let url;

        if (redirectLink) {
          url = redirectLink;
        } else {
          url = returnToUrl || '/e/myaccount/accountdetails';
        }

        window.location.href = url;
      },
    }),
  );
};

let loginTries = 0;

const loginCallbackError = (dispatch, payload, getState) => {
  const state = getState();
  const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
  const ucaProfileCookieToggle = state.toggles.UCA_PROFILE_COOKIE;
  const dtLoginFlow = state.toggles.DT_LOGIN_FLOW;
  const justRegistered = true;
  const { email, password } = payload;
  loginTries++;
  if (loginTries < 3) {
    amplifyLogin(
      email,
      password,
      loginCallbackError.bind(null, dispatch, payload),
      justRegistered,
      loginSuccessCallback.bind(null, dispatch, payload),
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    );
  } else {
    loginTries = 0;
    dispatch({ type: REJECTED_REGISTRATION, payload: 'Login error' });
  }
};

const signupErrCallback = (dispatch, email, err) => {
  let errorText = '';
  if (err.indexOf('already exists') !== -1) {
    errorText = `${registrationErrors.exist} ${email}`;
  } else if (
    err.indexOf('Username should be either an email or a phone number') !==
      -1 ||
    err.indexOf('e-mail address entered is invalid') !== -1
  ) {
    errorText = registrationErrors.invalidEmail;
  } else {
    errorText = registrationErrors.general;
  }
  dispatch({ type: REJECTED_REGISTRATION, payload: errorText });
};

export const signUp = payload => {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const ucaProfileCookieToggle = state.toggles.UCA_PROFILE_COOKIE;
    const dtLoginFlow = state?.toggles?.DT_LOGIN_FLOW;
    const loyaltyData = payload?.loyaltyData;
    const phoneNumber = payload?.phoneNumber;
    dispatch({ type: LOADING_REGISTRATION });
    amplifySignUp(
      payload.email,
      payload.password,
      payload.firstName,
      payload.lastName,
      signupErrCallback.bind(null, dispatch, payload.email),
      loginCallbackError.bind(null, dispatch, payload),
      loginSuccessCallback.bind(null, dispatch, { ...payload, ...loyaltyData }),
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
      loyaltyData,
      phoneNumber || '',
    );
  };
};

/**
 * Call when Login Page is fully loaded
 * @param isNewLayout {boolean} set true for new layout, false for old layout
 * @returns {function(*): *}
 */
export function resolvedLoginPage(isNewLayout) {
  return dispatch =>
    dispatch({
      type: RESOLVED_LOGIN_PAGE,
      payload: {
        isNewLayout,
      },
    });
}

export function resolvedRegistrationPage(isNewLayout) {
  return dispatch =>
    dispatch({
      type: RESOLVED_REGISTRATION_PAGE,
      payload: {
        isNewLayout,
      },
    });
}

export function resolvedForgotPasswordPage() {
  return dispatch =>
    dispatch({
      type: RESOLVED_FORGOT_PASSWORD_PAGE,
    });
}

export function resolvedCheckYourEmailPage() {
  return dispatch =>
    dispatch({
      type: RESOLVED_CHECK_YOUR_EMAIL_PAGE,
    });
}

export function resolvedResetPasswordNewPage() {
  return dispatch =>
    dispatch({
      type: RESOLVED_PASSWORD_RESET_NEW_PAGE,
    });
}
