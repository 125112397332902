import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compact from 'lodash/compact';
import get from 'lodash/get';
import values from 'lodash/values';
import classNames from 'classnames';
import { H2, EyeBrow4 } from 'bgo-common/Styleguide/Typography';
import ProductMedia from 'pdp/components/ProductPage/optimizationcomponents//ProductMediaTemplateB/productMedia';
import CheckoutOptionInfo from 'pdp/components/ProductPage/optimizationcomponents/Checkout/checkoutOptionInfo';
import ProductDetails from 'pdp/components/ProductPage/optimizationcomponents/ProductDetails/productDetails';
import getColorSkus from 'pdp/components/ProductPage/selectors/getColorSkus';
import {
  getActiveMedia,
  getActiveMediaIndex,
} from 'pdp/components/ProductPage/selectors/getMedia';
import {
  getColorOptions,
  getSizeOptions,
} from 'pdp/components/ProductPage/selectors/getOptions';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import getSelectedColorIndex from 'pdp/components/ProductPage/selectors/getSelectedColorIndex';
import getSelectedSku from 'pdp/components/ProductPage/selectors/getSelectedSku';
import getSizeSkus from 'pdp/components/ProductPage/selectors/getSizeSkus';
import { closeModal } from 'shared/components/Modal/actions';
import Button from 'shared/components/Button/button';
import { addWishlist } from '../../actions';
import '../WishlistButtons/wishlistButtons.scss';
import './wishlist.scss';

const sizeNotSelectedError = 'Please select a size';

class EditWishlistModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatePressed: false,
    };
  }

  componentWillUnmount() {
    this.props.clearProductData();
  }

  getValidationErrors = () => {
    const errors = {};
    const sizeOptions = getSizeOptions(this.props.product);
    if (
      !(this.props.product?.options?.selectedSizeIndex >= 0) &&
      sizeOptions?.values?.length
    ) {
      errors.size = sizeNotSelectedError;
    }
    return errors;
  };

  render() {
    const { props } = this;

    const {
      isGroup,
      firstGroupProduct,
      isCloudinary,
      wishlistData,
      product,
      isGreyCssFix,
    } = props;

    let deliveryDate = null;
    if (wishlistData && wishlistData.perishable && wishlistData.deliveryDate) {
      deliveryDate = wishlistData.deliveryDate;
    }

    const activeIndex = getActiveMediaIndex(product);
    const media = getActiveMedia(product);
    const groupMedia = compact([media.main, ...values(media.alternate || [])]);
    const closeModal = () => {
      props.closeModal('EditWishlistModal');
    };
    const arrows = true;
    const showUpdate = !!(
      getColorOptions(product).values.length > 1 ||
      getSizeOptions(product).values.length > 1 ||
      product.replenishable ||
      wishlistData.perishable
    );

    const validationErrors = this.getValidationErrors();

    const updateItem = () => {
      this.setState({
        updatePressed: true,
      });

      if (Object.keys(validationErrors)?.length > 0) {
        return;
      }

      const colorOptions = getColorOptions(product);
      const sizeOptions = getSizeOptions(product);
      const selectedColorIndex = getSelectedColorIndex(product);
      const selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);
      const colorSkus = getColorSkus(product, selectedSizeIndex);
      const sizeSkus = getSizeSkus(product, selectedColorIndex);
      const selectedSku = getSelectedSku(
        { colorSkus, sizeSkus },
        { colorOptions, sizeOptions },
        { selectedColorIndex, selectedSizeIndex },
      );
      if (product.replenishable) {
        wishlistData.replenishInterval = product.replenishInterval;
      }
      if (product.perishable) {
        wishlistData.deliveryDate = product.deliveryDate;
      }
      props.addWishlist(wishlistData, selectedSku, wishlistData.sku.id);
      closeModal();
    };

    return (
      <Fragment>
        <div className="editTitle">
          <H2>Edit Item</H2>
        </div>
        <div className="edit-image-container">
          <ProductMedia
            productId={wishlistData.id}
            showSoldOutOverlay={!false}
            name={wishlistData.name}
            media={media}
            activeIndex={activeIndex}
            displayOutfittingText
            isCloudinary={isCloudinary}
            openFromEditWishlist
            isGroup={isGroup}
            firstGroupProduct={firstGroupProduct}
            groupMedia={groupMedia}
            arrows={arrows}
          />
        </div>
        <div
          className={classNames(
            'edit-product-details',
            isGreyCssFix && 'edit-product-details-tablet',
          )}
        >
          <ProductDetails product={product} />
          <CheckoutOptionInfo
            product={product}
            hideFromEditWishlist={false}
            deliveryDate={deliveryDate}
            wishlistValidationErrors={
              this.state.updatePressed ? validationErrors : {}
            }
            updateColorState={() => {}}
            updateSizeState={() => {}}
          />
          {showUpdate && (
            <Button
              className="wb-default wb-primary updateItem"
              value="Update"
              onClick={() => updateItem()}
            />
          )}
          <span
            className="cancelEdit hide-on-desktop hide-on-tablet"
            onClick={() => closeModal()}
          >
            <EyeBrow4>Cancel</EyeBrow4>
          </span>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = getProduct(state, productId);
  const isGroup = get(state, 'productCatalog.product.isGroup');
  const firstGroupProduct = get(
    state,
    'productCatalog.group.childProducts.productIds[0]',
  );
  // const media = get(state, 'productCatalog.group.media', {});

  return {
    isGroup,
    firstGroupProduct,
    product,
    isCloudinary: state.toggles.PHASE2_PLP_CLOUDINARY,
    isGreyCssFix: state.toggles.GREYCOLOR_CSS_FIX,
  };
};

const mapDispatchToProps = dispatch => ({
  addWishlist: (...args) => dispatch(addWishlist(...args)),
  closeModal: (...args) => dispatch(closeModal(...args)),
  clearProductData: () => dispatch({ type: 'RESET_PRODUCT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditWishlistModal);
