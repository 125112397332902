/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getPDPImageMediumUrl } from 'bgo-common/components/Util/product-cloudinary';
import { BUNDLE, TEMPLATE_B } from 'bgo-common/components/constants';
import classnames from 'classnames';
import { InView } from 'react-intersection-observer';
import { addBodyClass } from 'client-utils/bodyClass';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import { isMobile } from 'client-utils/utilities-page';
import { mark, measure } from 'client/utilities/utilities-performance';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  openProductImageZoom,
  setProductVideo,
  unsetProductVideo,
  zoomProductImage,
  zoomProductImageQl,
} from 'pdp/components/ProductPage/actions';
import Image, {
  NO_IMAGE_AVAILABLE_LARGE_CDN,
} from 'pdp/components/ProductPage/components/Image/image';
import { getDynamicImageURLForCustomizedProduct } from 'pdp/components/ProductPage/components/Monogram/dynamicImageUtils/getDynamicScene7ImageURL';
import ProductVideo from 'pdp/components/ProductPage/components/ProductMedia/components/ProductVideo/productVideo';
import OutfittingText from 'pdp/components/ProductPage/optimizationcomponents/ProductMediaTemplateB/optimizationcomponents/OutfittingText/outfittingText';
import getProduct, {
  getGroup,
  isGroup,
} from 'pdp/components/ProductPage/selectors/getProduct';
import { generatePdpAlt } from 'pdp/utilities/utilities-alt';
import Slider from 'react-slick';
import wishlistCtaActiveImg from 'bgo-common/assets/images/wishlistCtaActive.svg';
import SaveToWishlistAsIcon from '../../../../../../../wishlist/components/WishlistPage/components/Wishlist/saveToWishlistAsIcon';
import './mainMedia_optimized.scss';
import { removeWishlist } from '../../../../../../../wishlist/components/WishlistPage/actions';
import getSelectedSku from '../../../../selectors/getSelectedSku';
import getColorSkus from '../../../../selectors/getColorSkus';
import {
  getColorOptions,
  getSizeOptions,
} from '../../../../selectors/getOptions';
import getSizeSkus from '../../../../selectors/getSizeSkus';
import getSelectedColorIndex from '../../../../selectors/getSelectedColorIndex';

export class DumbMainMedia extends Component {
  constructor(props) {
    super(props);
    mark('product image (construct)');
    this.handleZoomClk = this.handleZoomClk.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.handlerVideoHoverOn = this.handlerVideoHoverOn.bind(this);
    this.handlerVideoHoverOff = this.handlerVideoHoverOff.bind(this);
    this.handlerZoomHoverOn = this.handlerZoomHoverOn.bind(this);
    this.handlerZoomHoverOff = this.handlerZoomHoverOff.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      zoomHover: false,
      videoHover: false,
      current: 0,
      firstLoaded: false,
    };
  }

  componentDidMount() {
    measure('product image (TTI)');
    measure('product image (self render time)', 'product image (construct)');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeIndex } = nextProps;
    const isSameIndex = activeIndex === this.props.activeIndex;
    if (this.slider && !isSameIndex) this.slider.slickGoTo(activeIndex);
  }

  handlerVideoHoverOn() {
    this.setState({ videoHover: true });
  }

  handlerVideoHoverOff() {
    this.setState({ videoHover: false });
  }

  handlerZoomHoverOn() {
    this.setState({ zoomHover: true });
  }

  handlerZoomHoverOff() {
    this.setState({ zoomHover: false });
  }

  toggleVideo() {
    const { videoActive, setProductVideo, unsetProductVideo } = this.props;
    videoActive ? unsetProductVideo() : setProductVideo();
  }

  handleZoomClk() {
    const {
      images,
      activeIndex,
      unsetProductVideo,
      zoomProductImage,
      zoomProductImageQl,
    } = this.props;
    unsetProductVideo();
    addBodyClass('zoom-open');
    this.props.sourceIsQL
      ? zoomProductImageQl(images, activeIndex)
      : zoomProductImage(images, activeIndex);
  }

  handleKeyDown(event) {
    if (
      this.props.modal.open &&
      this.props.modal.type === 'QLOutfittingSummary'
    ) {
      if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
        event.stopPropagation();
        this.handleZoomClk();
      }
    }
  }

  render() {
    const {
      name,
      brand = '',
      images = [],
      video = {},
      activeIndex = 0,
      videoActive = false,
      unsetProductVideo,
      arrows,
      product,
      displayOutfittingText = false,
      modal,
      isDomLoadComplete,
      staticImageToggle = false,
      imageInfiniteScrollToggle,
      openProductImageZoom,
      isCloudinary,
      openFromEditWishlist = false,
      zoomPdpToggle,
      showAddToWishlist,
      isDomestic,
      removeWishlist,
      saveToWishlistIconToggle,
      hideFromEditWishlist = true,
      device,
    } = this.props;

    const settings = {
      accessibility: true,
      arrows: images.length > 1 && arrows,
      dots: images.length > 1, // && dots,
      swipe: false,
      infinite: arrows,
      initialSlide: this.state.current,
      lazyLoad: true,
      fade: arrows,
      slidesToShow: 1,
      onLazyLoad: slide => {
        if (slide[0] === -1) {
          this.slider.slickGoTo(0);
        }
      },
      beforeChange: (current, next) => {
        this.setState({
          current: next,
        });
        this.slider && this.slider.slickGoTo(next);
      },

      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: images.length > 1,
            swipe: images.length > 1,
            infinite: imageInfiniteScrollToggle,
            arrows: false,
          },
        },
      ],
    };

    const handleZoom = (images, index) => {
      openProductImageZoom(images, index, isCloudinary, zoomPdpToggle);
    };

    const isValidProductForDynamicImageUpdate = () => {
      return (
        product.isPersonalizationSelected &&
        product.productFlags.previewSupported
      );
    };

    const isPreviewSupported = () => {
      return product.productFlags && !product.productFlags.previewSupported;
    };
    if (images.length > 0 && isValidProductForDynamicImageUpdate()) {
      const thumbnailImageWidth = 75;
      const mediumImageWidth = 456;
      const largeImageWidth = 1200;
      const customizedDynamicImageUrl = getDynamicImageURLForCustomizedProduct(
        product,
      );
      images[0].thumbnail &&
        (images[0].thumbnail.url = `${customizedDynamicImageUrl}&wid=${thumbnailImageWidth}`);
      images[0].medium &&
        (images[0].medium.url = `${customizedDynamicImageUrl}&wid=${mediumImageWidth}`);
      images[0].large &&
        (images[0].large.url = `${customizedDynamicImageUrl}&wid=${largeImageWidth}`);
    }
    let imageSizeUrl = 'large.url';
    if (modal.type === 'QLOutfittingSummary') {
      imageSizeUrl = 'medium.url';
    }

    const getSelectedSkuinComp = () => {
      const colorOptions = getColorOptions(product);
      const sizeOptions = getSizeOptions(product);
      const selectedColorIndex = getSelectedColorIndex(product);
      let selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);

      if (selectedSizeIndex === -1) {
        selectedSizeIndex = sizeOptions?.values.findIndex(
          item => item?.inactive === false,
        );
      }

      const colorSkus = getColorSkus(product, selectedSizeIndex);
      const sizeSkus = getSizeSkus(product, selectedColorIndex);
      const ag = getSelectedSku(
        { colorSkus, sizeSkus },
        { colorOptions, sizeOptions },
        { selectedColorIndex, selectedSizeIndex },
      );

      return ag;
    };

    const isSkuAddedToWishlist = selectedSku => {
      const { wishlist } = this.props;

      if (
        wishlist &&
        wishlist.items &&
        wishlist.items.length > 0 &&
        selectedSku
      ) {
        for (let i = 0; i < wishlist.items.length; i++) {
          if (wishlist.items[i].skuId === selectedSku.id) {
            return true;
          }
        }
      }
      return false;
    };

    const selectedSKULocal = getSelectedSkuinComp();

    const onClickRemoveFromWishlist = () => {
      if (window && window?.utag && window?.utag?.link) {
        const data = {
          event_name: 'favoriteItem',
          favorite_item_status: 'unfavorite',
          product_interaction: 'remove from favorite',
          ...(product?.metadata?.cmosItem && {
            product_favorited_item: [product.metadata.cmosItem],
          }),
        };
        window.utag.link(data);
      }
      removeWishlist(selectedSKULocal?.id);
    };

    const newCond =
      isDomestic &&
      showAddToWishlist &&
      hideFromEditWishlist &&
      !product?.isZeroDollarProduct &&
      saveToWishlistIconToggle;

    const altImages = images.map((image, index) => {
      return index < 2 ? (
        <div
          className="product-image"
          onClick={() => handleZoom(images, index)}
          onKeyPress={() => handleZoom(images, index)}
          key={`product-media-item-${index}`}
        >
          <Image
            alt={generatePdpAlt(brand, name, index, images.length)}
            src={getPDPImageMediumUrl(image, isCloudinary, TEMPLATE_B)}
            isCloudinary={isCloudinary}
          />
          <div className="product-image__overlay" />
        </div>
      ) : (
        <InView rootMargin="500px" triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="product-image"
              onClick={() => handleZoom(images, index)}
              onKeyPress={() => handleZoom(images, index)}
              key={`product-media-item-${index}`}
            >
              {inView && (
                <Image
                  alt={generatePdpAlt(brand, name, index, images.length)}
                  src={getPDPImageMediumUrl(image, isCloudinary, TEMPLATE_B)}
                  isCloudinary={isCloudinary}
                />
              )}
              <div
                className={classnames(
                  `product-image__overlay`,
                  inView && 'product-image__overlay__fade-out',
                )}
              />
            </div>
          )}
        </InView>
      );
    });
    const renderSingleImage = images[0] && (
      <Fragment>
        <InView rootMargin="500px" triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="main-media__single"
              onClick={
                !openFromEditWishlist && isMobile()
                  ? () => handleZoom(images, 0)
                  : this.handleZoomClk
              }
              onKeyPress={
                !openFromEditWishlist && isMobile()
                  ? () => handleZoom(images, 0)
                  : this.handleZoomClk
              }
            >
              {inView && (
                <Image
                  alt={generatePdpAlt(brand, name, 0, 0)}
                  src={get(
                    images[0],
                    imageSizeUrl,
                    NO_IMAGE_AVAILABLE_LARGE_CDN,
                  )}
                  timeout={this.props.productPageImageTimeout}
                  quantumImageErrorToggle={this.props.quantumImageErrorToggle}
                  isPdp
                />
              )}
            </div>
          )}
        </InView>
      </Fragment>
    );
    const videoImage = video.thumbnail && (
      <div key="video" className="video">
        <div
          onClick={this.toggleVideo}
          onKeyDown={this.toggleVideo}
          role="button"
          tabIndex="0"
          className="grid-100 tablet-grid-100 mobile-grid-100"
        >
          <div
            className={classnames('mobile-slick__video-overlay', {
              'image-infinite-scroll': this.props.imageInfiniteScrollToggle,
            })}
          />
          <Image
            alt={`Video: ${name}`}
            src={get(video, 'thumbnail.url', '').replace('_mg', '_mz')}
            timeout={this.props.productPageImageTimeout}
            quantumImageErrorToggle={this.props.quantumImageErrorToggle}
            isPdp
          />
        </div>
      </div>
    );

    // const mainMediaClass = sourceIsQL ?
    //   'main-media grid-100 tablet-grid-100'
    //   : 'main-media grid-90 tablet-grid-80';
    const mainMediaClass = 'main-media 3';

    return (
      <div className={mainMediaClass}>
        {videoActive && (
          <ProductVideo video={video} unsetProductVideo={unsetProductVideo} />
        )}

        {!videoActive && (
          <div
            className="main-media__container"
            tabIndex={0}
            onKeyPress={e => {
              e.key === 'Enter' &&
                !openFromEditWishlist &&
                handleZoom(images, activeIndex);
            }}
          >
            {isPreviewSupported() && product.isPersonalizationSelected && (
              <div className="main-media__overlay__no-preview">
                <span>No Preview Available</span>
              </div>
            )}
            {!isEmpty(images) && (!isDomLoadComplete && staticImageToggle) ? (
              renderSingleImage
            ) : (
              <Slider
                key={this.state.current}
                {...settings}
                ref={ref => {
                  this.slider = ref;
                }}
              >
                {altImages.concat(videoImage)}
              </Slider>
            )}
            {newCond &&
              (!isSkuAddedToWishlist(selectedSKULocal) ? (
                <>
                  <SaveToWishlistAsIcon
                    selectedSku={selectedSKULocal}
                    productId={product?.id}
                    styleUpdate={`${
                      images?.length === 1 ? 'right_zero' : ''
                    } groups_wishlist`}
                  />
                </>
              ) : (
                <span
                  className={`saveToWishlist wishlistCtaButton ${
                    images?.length === 1 ? 'right_zero' : ''
                  } groups_wishlist ${device?.isMobile ? 'mobileBtn' : ''}`}
                  onClick={onClickRemoveFromWishlist}
                >
                  <img
                    alt="Wishlist Cta"
                    aria-hidden="true"
                    className="wishlist-cta-img "
                    src={wishlistCtaActiveImg}
                  />
                </span>
              ))}
          </div>
        )}
        {displayOutfittingText && <OutfittingText />}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // PDP CommonBundle Image : Check if it's a group product and bundle pdp
  const isGroupProduct = isGroup(state);

  // PDP CommonBundle Image : if it's group product and bundle pdp, only fetch group data as product, otherwise get product from childProduct list
  const product =
    isGroupProduct && props.isCommonBundle
      ? getGroup(state)
      : getProduct(state, props.productId);

  const isDomLoadComplete = get(state, 'page.isDomLoadComplete', false);
  const staticImageToggle = get(state, 'toggles.PDP_STATIC_IMAGE', false);
  const quantumImageErrorToggle = get(
    state,
    'toggles.QUANTUM_ERROR_IMAGE_REPORT',
    false,
  );
  const imageInfiniteScrollToggle = get(
    state,
    'toggles.IMAGE_INFINITE_SCROLL',
    false,
  );
  const { modal } = state;
  const zoomPdpToggle = get(state, 'toggles.ZOOM_FN_PDP', false);
  const device = get(state, 'device', {});

  return {
    videoActive: product.videoActive,
    product,
    modal,
    isDomLoadComplete,
    staticImageToggle,
    productPageImageTimeout: state.productCatalog.productPageImageTimeout,
    quantumImageErrorToggle,
    imageInfiniteScrollToggle,
    zoomPdpToggle,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    showAddToWishlist: state.toggles.BG_PHASE_2_WISHLIST,
    wishlist: state?.wishlist?.wishlist,
    saveToWishlistIconToggle: state.toggles.PDP_WISHTLIST_CTA_AS_ICON,
    device,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  zoomProductImage: (...args) => dispatch(zoomProductImage(productId, ...args)),
  zoomProductImageQl: (...args) =>
    dispatch(zoomProductImageQl(productId, ...args)),
  setProductVideo: () => dispatch(setProductVideo(productId)),
  unsetProductVideo: () => dispatch(unsetProductVideo(productId)),
  openProductImageZoom: (...args) =>
    dispatch(openProductImageZoom(productId, ...args)),
  removeWishlist: (...args) => dispatch(removeWishlist(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMainMedia);
