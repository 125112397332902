import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import get from 'lodash/get';
import axios from 'axios';
import classnames from 'classnames';
import { unescape } from 'he';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import { InView } from 'react-intersection-observer';
import { apiForHttpRequest } from 'universal/http-client';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import NO_IMAGE_AVAILABLE from 'bgo-common/assets/images/no-image.png';
import {
  transformCloudinaryUrl,
  isValidCloudinaryUrl,
} from 'bgo-common/components/Util/product-cloudinary';

import {
  SOLD_OUT_TEXT,
  SOLD_OUT_TEXT_ALIAS,
  BRAND_CODE,
  // PROMOTION_TYPE_113,
  TEMPLATE_TYPE,
  GROUP_TYPE,
  SUPER_GRID_5,
  VIEW_BY_OPTIONS,
} from 'plp/constants';
import { setSelectedColorCode } from 'pdp/components/ProductPage/actions';
import {
  showQLModalWindow,
  updateSwatchesValue,
} from 'plp/ProductListPage/actions';
import Toggle from 'shared/components/Toggle/toggle';
import { CLOUDINARY_SIZE } from 'bgo-common/components/constants';
import Badges from 'bgo-common/client/common/components/Badges/badges';
import './product.scss';
import Image from './components/Image/image';
import ImageLazyLoad from './components/Image/imageLazyLoad';
import ImageLazyLoadIntersectionObserver from './components/Image/imageLazyLoadIntersectionObserver';
import ColorSwatches from './components/ColorSwatches/colorSwatches';
// import FavoriteButton from './components/Favorite/favoriteButton';
// import Flags from './components/Flags/flags';
import Promotions from './components/Promotions/promotions';
import ProductNameAndPrice from './components/ProductNameAndPrice/productNameAndPrice';
import ProductMetadata from './components/ProductMetadata/productMetadata';
// import { SUPER_GRID_3, SUPER_GRID_5 } from '../../../../../constants';

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}

function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}

const getMinChildPrice = childProducts =>
  childProducts.reduce(
    (acc, { rprc }) => {
      const intRprc = parseInt(rprc.replace(/[^0-9.]/g, ''), 10);
      return !acc.intPrice || acc.intPrice > intRprc
        ? { price: rprc, intPrice: intRprc }
        : acc;
    },
    { price: '', intPrice: 0 },
  ).price;

// const isGiftCard = promotions => {
//   return promotions.some(
//     promotion =>
//       promotion.templateHTML &&
//       GIFT_CARD &&
//       promotion.templateHTML.toLowerCase().includes(GIFT_CARD.toLowerCase()),
//   );
// };

export const ProductLink = props => {
  return (
    <Link
      target="_self"
      onClick={() => onProductClick(props)}
      className={props.className}
      to={props.to}
      aria-label="Product Url"
    >
      {props.children}
    </Link>
  );
};

function onQuickLookButtonClick(props, colorCode, navPath, isDisplayAsGroups) {
  if (get(props, 'colorOptions', []).length !== 0) {
    props.setSelectedColorCode(colorCode);
  }
  props.showQLModalWindow(
    props.id,
    props.productUrl,
    navPath,
    isDisplayAsGroups,
  );
}

function onProductClick(props) {
  if (props.colorOptions && props.colorOptions.length) {
    const colorCode = props.colorOptions[props.index].key;
    if (props.setSelectedColorCode !== undefined) {
      props.setSelectedColorCode(colorCode);
    }
  }

  if (!isEmpty(props.clickTrackingUrl)) {
    if (props.rrApiClickSuppressAddnlHeaders) {
      axios
        .get(props.clickTrackingUrl)
        .then(() => null)
        .catch(() => null);
    } else {
      const api = apiForHttpRequest();
      api.get(props.clickTrackingUrl);
    }
  }
}

export class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColorSwatchIndex: 0,
      hoveredColorSwatchIndex: -1,
      selectedColorSwatchCode: '',
      defaultSwatchSelected: false,
      superGridMode: props.superGridMode,
    };
    this.hasSwatchBeenSelectedManually = false;
    this.changeSelectedColorSwatchIndex = this.changeSelectedColorSwatchIndex.bind(
      this,
    );
    this.changeSelectedColorSwatchCode = this.changeSelectedColorSwatchCode.bind(
      this,
    );
    this.handleMouseOutForSwatch = this.handleMouseOutForSwatch.bind(this);
    this.handleMouseOverForSwatch = this.handleMouseOverForSwatch.bind(this);
    this.getProductImageURL = this.getProductImageURL.bind(this);
    this.getProductAltImageURL = this.getProductAltImageURL.bind(this);
    this.getDefaultSwatchImageURL = this.getDefaultSwatchImageURL.bind(this);
    this.isDefaultSwatch = this.isDefaultSwatch.bind(this);
    this.getValidImageUrl = this.getValidImageUrl.bind(this);
    this.handleOnClickForColorSwatch = this.handleOnClickForColorSwatch.bind(
      this,
    );

    const filteredColorSwatchIndex = this.getFilteredColorSwatchIndex(props);
    if (filteredColorSwatchIndex !== -1) {
      setTimeout(
        () => this.changeSelectedColorSwatchIndex(filteredColorSwatchIndex),
        0,
      );
    }
  }

  getDefaultProps() {
    return {
      isPromoTile: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filteredColorSwatchIndex = this.getFilteredColorSwatchIndex(
      nextProps,
    );
    if (filteredColorSwatchIndex > -1) {
      this.changeSelectedColorSwatchIndex(filteredColorSwatchIndex);
    }
    if (this.state.superGridMode !== nextProps.superGridMode) {
      this.setState({
        superGridMode: nextProps.superGridMode,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isFavorite =
      nextProps.isFavorite !== this.props.isFavorite ||
      nextState !== this.state ||
      nextProps.clickTrackingUrl !== this.props.clickTrackingUrl;
    const isFiltered =
      nextProps.filteredColors !== this.props.filteredColors ||
      nextProps.filtersSelected !== this.props.filtersSelected;
    return isFavorite || isFiltered || nextProps.url !== this.props.url;
  }

  getFilteredColorSwatchIndex(props) {
    if (isEmpty(props.colorOptions)) {
      return -1;
    }
    return findIndex(props.colorOptions, colorOption =>
      includes(props.filteredColors, colorOption.facet),
    );
  }

  getImageUrlByPLPConfigSelector(selector, media, mainMediaImageUrl) {
    return (
      (selector === 'm' ? get(media, 'main') : get(media, 'alt')) ||
      isValidCloudinaryUrl(mainMediaImageUrl)
    );
  }

  getImageUrlByViewBy(imageTypeDesktopTab, imageTypeMob, media) {
    const {
      viewTypeVisibility,
      parentCategoryId,
      plpConfigurator,
      url,
      altImage,
    } = this.props;

    let urlByViewBy;
    if (media) {
      if (viewTypeVisibility === VIEW_BY_OPTIONS[0]) {
        urlByViewBy =
          this.getImageUrlByPLPConfigSelector(
            plpConfigurator.viewBy[parentCategoryId].product[0],
            media,
            url,
          ) ||
          this.getImageUrlByPLPConfigSelector(
            plpConfigurator.viewBy[parentCategoryId].outfit[0],
            media,
          );
      } else {
        urlByViewBy = this.getImageUrlByPLPConfigSelector(
          plpConfigurator.viewBy[parentCategoryId].outfit[0],
          media,
        );
      }
    }
    return this.transformCloudinaryUrl(
      urlByViewBy || url || altImage,
      imageTypeDesktopTab,
      imageTypeMob,
    );
  }

  getDefaultSwatchImageURL(
    colorOptions,
    isCloudinary,
    imageTypeDesktopTab,
    imageTypeMob,
  ) {
    const { isViewByFilterEnabled } = this.props;
    if (!isEmpty(colorOptions)) {
      const activeColorOptions =
        colorOptions[this.state.selectedColorSwatchIndex];
      if (isViewByFilterEnabled) {
        return this.getImageUrlByViewBy(
          imageTypeDesktopTab,
          imageTypeMob,
          activeColorOptions,
        );
      }

      const imageUrl = get(activeColorOptions, 'main') || this.props.url;
      if (isCloudinary) {
        return this.transformCloudinaryUrl(
          imageUrl,
          imageTypeDesktopTab,
          imageTypeMob,
        );
      } else return imageUrl;
    } else if (isCloudinary) {
      if (isViewByFilterEnabled) {
        return this.getImageUrlByViewBy(imageTypeDesktopTab, imageTypeMob);
      }
      return this.transformCloudinaryUrl(
        this.props.url,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    } else return this.props.url;
  }

  getProductImageURL(
    colorOptions,
    isCloudinary,
    imageTypeDesktopTab,
    imageTypeMob,
  ) {
    const { isViewByFilterEnabled, url } = this.props;

    if (!isEmpty(colorOptions)) {
      const hoveredSwatchIndex = this.state.hoveredColorSwatchIndex;
      const selectedSwatchIndex = this.state.selectedColorSwatchIndex;

      const activeIndex =
        hoveredSwatchIndex !== -1 ? hoveredSwatchIndex : selectedSwatchIndex;
      const activeColorOptions = colorOptions[activeIndex];
      if (isViewByFilterEnabled) {
        return this.getImageUrlByViewBy(
          imageTypeDesktopTab,
          imageTypeMob,
          activeColorOptions,
        );
      }
      const mainMediaUrl = get(activeColorOptions, 'main');
      const alternateMediaUrl = get(activeColorOptions, 'alt');
      const validImageUrl = mainMediaUrl || alternateMediaUrl || url;
      if (isCloudinary) {
        return this.transformCloudinaryUrl(
          validImageUrl,
          imageTypeDesktopTab,
          imageTypeMob,
        );
      } else return validImageUrl;
    } else if (isCloudinary) {
      return this.transformCloudinaryUrl(
        this.props.url,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    } else return this.props.url;
  }

  getProductAltImageURL(
    colorOptions,
    isCloudinary,
    imageTypeDesktopTab,
    imageTypeMob,
  ) {
    const {
      isViewByFilterEnabled,
      viewTypeVisibility,
      parentCategoryId,
      plpConfigurator,
      altImage,
      url,
    } = this.props;
    const index = this.state.selectedColorSwatchIndex;
    const activeColorOptions = colorOptions ? colorOptions[index] : {};

    if (isViewByFilterEnabled) {
      let urlByViewBy;
      if (viewTypeVisibility === VIEW_BY_OPTIONS[1]) {
        urlByViewBy =
          this.getImageUrlByPLPConfigSelector(
            plpConfigurator.viewBy[parentCategoryId].product[0],
            activeColorOptions,
            altImage,
          ) ||
          this.getImageUrlByPLPConfigSelector(
            plpConfigurator.viewBy[parentCategoryId].outfit[0],
            activeColorOptions,
            url,
          );
      } else {
        urlByViewBy = this.getImageUrlByPLPConfigSelector(
          plpConfigurator.viewBy[parentCategoryId].outfit[0],
          activeColorOptions,
        );
      }
      return this.transformCloudinaryUrl(
        urlByViewBy || altImage,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    }

    const altMediaUrl = get(activeColorOptions, 'alt');
    const validCloudinaryImageAltUrl = altMediaUrl || altImage;
    if (isCloudinary) {
      return this.transformCloudinaryUrl(
        validCloudinaryImageAltUrl,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    } else return altImage;
  }

  getValidImageUrl(imageUrl) {
    if (!imageUrl) {
      return NO_IMAGE_AVAILABLE;
    }
    return imageUrl;
  }

  getImageSource(
    colorOptionValues,
    errorMessage,
    isCloudinary,
    imageTypeDesktopTab,
    imageTypeMob,
    // url,
  ) {
    let imageUrl;
    if (this.isDefaultSwatch()) {
      imageUrl = this.getDefaultSwatchImageURL(
        colorOptionValues,
        isCloudinary,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    } else {
      imageUrl = this.getProductImageURL(
        colorOptionValues,
        isCloudinary,
        imageTypeDesktopTab,
        imageTypeMob,
      );
    }
    return this.getValidImageUrl(imageUrl);
  }

  getPromotionsWithDogEar(promotions) {
    return find(promotions, promotion => promotion.thumbnailOutlineDisplayFlag);
  }

  getStyleForDogEar(promotion) {
    const backgroundColor = promotion.thumbnailOutlineColor;
    return backgroundColor
      ? { backgroundColor: `#${backgroundColor}` }
      : undefined;
  }

  getDiscount(discount) {
    return (
      discount > 0 && (
        <div className="product__discount">{`As much as ${discount}% Off`}</div>
      )
    );
  }

  transformCloudinaryUrl(url, imageTypeDesktopTab, imageTypeMob) {
    return transformCloudinaryUrl(
      url,
      CLOUDINARY_SIZE.PRODUCT,
      imageTypeDesktopTab,
      imageTypeMob,
    );
  }

  isDefaultSwatch() {
    return (
      !this.hasSwatchBeenSelectedManually &&
      this.state.hoveredColorSwatchIndex === -1
    );
  }

  changeSelectedColorSwatchIndex(index) {
    this.setState({ selectedColorSwatchIndex: index < 0 ? 0 : index });
  }

  changeSelectedColorSwatchCode(swatchCode) {
    this.setState({ selectedColorSwatchCode: swatchCode });
  }

  handleOnClickForColorSwatch(index, swatchCode) {
    this.hasSwatchBeenSelectedManually = true;
    this.changeSelectedColorSwatchIndex(index);
    this.changeSelectedColorSwatchCode(swatchCode);
  }

  handleMouseOverForSwatch(swatchIndex) {
    this.setState({ hoveredColorSwatchIndex: swatchIndex });
  }

  handleMouseOutForSwatch() {
    this.setState({ hoveredColorSwatchIndex: -1 });
  }

  isValidDisplayableSwatch(colorOptions) {
    const options = { ...colorOptions };
    options.values = options.values
      ? partition(options.values, 'displaySkuImg')[0]
      : [];
    options.values = options.values
      ? partition(options.values, { url: '' })[1]
      : [];
    return options.values.length >= 1;
  }

  isSuperGridFive() {
    return this.state.superGridMode !== SUPER_GRID_5;
  }

  render() {
    if (this.props.isGroup) {
      for (let i = 0; i < this.props.childProducts.length; i++) {
        if (
          isEmpty(this.props.childProducts[i].name) ||
          isEmpty(this.props.childProducts[i].rprc)
        ) {
          return null;
        }
      }
    } else if (isEmpty(this.props.name) || isEmpty(this.props.retailPrice)) {
      return null;
    }

    const {
      isPLP = false,
      name = '',
      id = '',
      retailPrice,
      originalPrice,
      promotionalPrice,
      cmosItem = '',
      promotions = [],
      promotionalText,
      colorOptions = [],
      productUrl,
      focusProductId,
      productFlags = {},
      enableLazyLoading = true,
      uuid,
      page,
      position,
      navPath,
      applicableDiscount,
      onlyXItemsLeftStockLevel,
      quickLookToggle,
      favoriteToggle,
      productMetadata = {},
      productMetadataStatus = {},
      childProducts = [],
      childCount,
      isGroup,
      hasMoreItems = false,
      plpImageSwatchLazyLoadBeta = false,
      plpImageLazyLoadBeta = false,
      moveProductTogglesToList = false,
      productComponentRedesign,
      favoritesQuickLookNewLayout = false,
      // isFullWidth = false,
      isNewAspectRatioCategory = false,
      srpImgAspectRatio2to3 = false,
      searchFlow = false,
      favoriteFlow = false,
      // isMobileDevice = false,
      templateType,
      isCloudinary,
      // url,
      isAltImageRequired = true,
      designer: designerFromProp = '',
      isTalonOne,
      isTalonOneGp,
      isFuturePromo,
      quickViewPLP,
      pathname,
      p4LayoutToggleEnabled,
      esgPLPToggle,
      showESGLabel,
    } = this.props;
    const isBeauty = pathname?.includes('beauty');
    const search = get(this.props, 'location.search', false);
    const plpAspectRatio = !isNewAspectRatioCategory && isPLP;
    const searchFlowAspectRatio = !srpImgAspectRatio2to3 && searchFlow;
    const designer = unescape(String(designerFromProp));
    const childProductNames = childProducts
      .map(product => product.name)
      .join(' ');
    const productName = stripHtml(unescape(isGroup ? childProductNames : name));
    const colorOptionValues = filter(
      colorOptions,
      colorOption => colorOption.displaySkuImg || !colorOption.displaySkuImg,
    );
    const childItemIdValue = `BG${cmosItem}_${get(
      colorOptions[this.state.selectedColorSwatchIndex],
      'key',
      '',
    )}`;
    const errorMessage =
      `EVENT_TYPE=MISSING_IMAGE CMOS_ITEM=${this.props.cmosItem}` +
      `CMOS_CATALOG_ID=${this.props.cmosCatalogId} PIMSTYLE=${this.props.pimStyle}` +
      `PRODUCT_ID=${this.props.id} BRAND_CODE=${BRAND_CODE}`;
    const defaultQuery = {
      childItemId: childItemIdValue,
      uuid,
      page,
      position,
      navpath: navPath,
    };
    const query = focusProductId
      ? { ...defaultQuery, focusProductId }
      : defaultQuery;
    /* istanbul ignore next */
    const showPromoPreview =
      new URLSearchParams(search).get('promoPreview') || false;
    const renderThumbnail = (
      index,
      isCloudinary,
      imageTypeDesktopTab = 'normal',
      imageTypeMob = 'normal',
      // url,
    ) => {
      const imageSource = this.getImageSource(
        colorOptionValues,
        errorMessage,
        isCloudinary,
        imageTypeDesktopTab,
        imageTypeMob,
        // url,
      );

      if (this.props.swatchOptimizationToggle === true) {
        return (
          <ImageLazyLoadIntersectionObserver
            src={imageSource}
            altImage={this.getProductAltImageURL(
              colorOptionValues,
              isCloudinary,
              imageTypeDesktopTab,
              imageTypeMob,
            )}
            alt={trimSpaces(`${designer} ${productName}`)}
            title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
            errorMessage={errorMessage}
            enableLazyLoading={enableLazyLoading}
            defaultURL={this.props.url}
            favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
            quickLookToggle={quickLookToggle}
            isBeauty={isBeauty}
            quickViewPLP={quickViewPLP}
            displayable={this.props.displayable}
            productId={this.props.id}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            pimStyle={this.props.pimStyle}
            favoriteToggle={favoriteToggle}
            showQLModalWindow={() => {
              onQuickLookButtonClick(
                this.props,
                this.state.selectedColorSwatchCode,
                navPath,
                this.props.isDisplayAsGroups,
              );
            }}
            isCloudinary={isCloudinary}
          />
        );
      }
      let thumbnailMarkup;
      if (moveProductTogglesToList) {
        thumbnailMarkup = plpImageLazyLoadBeta ? (
          <ImageLazyLoad
            src={imageSource}
            altImage={
              isAltImageRequired &&
              this.getProductAltImageURL(
                colorOptionValues,
                isCloudinary,
                imageTypeDesktopTab,
                imageTypeMob,
              )
            }
            alt={
              this.props.displayable
                ? trimSpaces(`${designer} ${productName}`)
                : SOLD_OUT_TEXT_ALIAS
            }
            title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
            errorMessage={errorMessage}
            enableLazyLoading={enableLazyLoading}
            defaultURL={this.props.url}
            favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
            quickLookToggle={quickLookToggle}
            isBeauty={isBeauty}
            quickViewPLP={quickViewPLP}
            productId={this.props.id}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            pimStyle={this.props.pimStyle}
            displayable={this.props.displayable}
            favoriteToggle={favoriteToggle}
            showQLModalWindow={() => {
              onQuickLookButtonClick(
                this.props,
                this.state.selectedColorSwatchCode,
                navPath,
                this.props.isDisplayAsGroups,
              );
            }}
            isCloudinary={isCloudinary}
          />
        ) : (
          <Image
            src={imageSource}
            altImage={this.getProductAltImageURL(
              colorOptionValues,
              isCloudinary,
              imageTypeDesktopTab,
              imageTypeMob,
            )}
            alt={
              this.props.displayable
                ? trimSpaces(`${designer} ${productName}`)
                : SOLD_OUT_TEXT_ALIAS
            }
            title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
            errorMessage={errorMessage}
            enableLazyLoading={enableLazyLoading}
            favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
            quickLookToggle={quickLookToggle}
            isBeauty={isBeauty}
            quickViewPLP={quickViewPLP}
            productId={this.props.id}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            pimStyle={this.props.pimStyle}
            favoriteToggle={favoriteToggle}
            displayable={this.props.displayable}
            showQLModalWindow={() => {
              onQuickLookButtonClick(
                this.props,
                this.state.selectedColorSwatchCode,
                navPath,
                this.props.isDisplayAsGroups,
              );
            }}
            isCloudinary={isCloudinary}
          />
        );
      } else {
        thumbnailMarkup = (
          <Toggle
            feature="PLP_IMAGE_LAZY_LOAD_BETA"
            fallback={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Image
                src={imageSource}
                altImage={this.getProductAltImageURL(
                  colorOptionValues,
                  isCloudinary,
                  imageTypeDesktopTab,
                  imageTypeMob,
                )}
                alt={
                  this.props.displayable
                    ? trimSpaces(`${designer} ${productName}`)
                    : SOLD_OUT_TEXT_ALIAS
                }
                title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
                errorMessage={errorMessage}
                enableLazyLoading={enableLazyLoading}
                favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
                quickLookToggle={quickLookToggle}
                isBeauty={isBeauty}
                quickViewPLP={quickViewPLP}
                productId={this.props.id}
                isFavorite={this.props.isFavorite}
                isDisplayAsGroups={this.props.isDisplayAsGroups}
                cmosItem={this.props.cmosItem}
                cmosCatalogId={this.props.cmosCatalogId}
                pimStyle={this.props.pimStyle}
                displayable={this.props.displayable}
                favoriteToggle={favoriteToggle}
                showQLModalWindow={() => {
                  onQuickLookButtonClick(
                    this.props,
                    this.state.selectedColorSwatchCode,
                    navPath,
                    this.props.isDisplayAsGroups,
                  );
                }}
                isCloudinary={isCloudinary}
              />
            }
          >
            <ImageLazyLoad
              src={this.getImageSource(colorOptionValues, errorMessage)}
              altImage={this.getProductAltImageURL(
                colorOptionValues,
                isCloudinary,
                imageTypeDesktopTab,
                imageTypeMob,
              )}
              alt={
                this.props.displayable
                  ? trimSpaces(`${designer} ${productName}`)
                  : SOLD_OUT_TEXT_ALIAS
              }
              title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
              errorMessage={errorMessage}
              enableLazyLoading={enableLazyLoading}
              defaultURL={this.props.url}
              favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
              quickLookToggle={quickLookToggle}
              isBeauty={isBeauty}
              quickViewPLP={quickViewPLP}
              productId={this.props.id}
              isFavorite={this.props.isFavorite}
              isDisplayAsGroups={this.props.isDisplayAsGroups}
              cmosItem={this.props.cmosItem}
              cmosCatalogId={this.props.cmosCatalogId}
              pimStyle={this.props.pimStyle}
              favoriteToggle={favoriteToggle}
              displayable={this.props.displayable}
              showQLModalWindow={() => {
                onQuickLookButtonClick(
                  this.props,
                  this.state.selectedColorSwatchCode,
                  navPath,
                  this.props.isDisplayAsGroups,
                );
              }}
              isCloudinary={isCloudinary}
            />
          </Toggle>
        );
      }

      return thumbnailMarkup;
    };

    function renderProductNameAndPriceAndPromotionsForGroups() {
      return (
        <Fragment>
          <h2
            className={classnames({
              'seo-wrapper': true,
              'product-name': true,
              'inherit-font-size': true,
              'text-truncate': productComponentRedesign,
            })}
          >
            {childProducts &&
              childProducts.length &&
              childProducts[0].pprc &&
              childProducts.map((pro, i) => (
                <div
                  className="promoText"
                  style={i === 0 ? { paddingTop: '0px' } : {}}
                >
                  <p className="productText">{pro.name}</p>
                  <p className="productText">
                    {pro.oprc ? (
                      <span>
                        <span
                          style={
                            isTalonOneGp
                              ? { color: '#b30015' }
                              : { color: '#b30015' }
                          }
                        >
                          {pro.rprc}
                        </span>
                        <span
                          className={
                            isTalonOneGp ? 'promoRetail' : 'promoRetail'
                          }
                          style={{
                            paddingLeft: '5px',
                          }}
                        >
                          {pro.oprc}
                        </span>
                        <br />
                      </span>
                    ) : (
                      <span className="">{pro.rprc}</span>
                    )}
                    <span
                      style={
                        isTalonOneGp
                          ? { color: '#b30015' }
                          : { color: '#b30015' }
                      }
                    >
                      {isFuturePromo &&
                      showPromoPreview === 'true' &&
                      pro.promoTxt &&
                      pro.promoTxt.toLowerCase().includes('future')
                        ? ` ${pro.promoTxt ? pro.promoTxt : ''} ${
                            pro.pprc ? pro.pprc : ''
                          }`
                        : ''}
                      {pro.promoTxt &&
                      !pro.promoTxt.toLowerCase().includes('future')
                        ? ` ${pro.promoTxt ? pro.promoTxt : ''} ${
                            pro.pprc ? pro.pprc : ''
                          }`
                        : ''}
                    </span>
                  </p>
                </div>
              ))}
            {childProducts &&
              childProducts.length &&
              !childProducts[0].pprc &&
              childProducts[0].rprc &&
              !childProducts[0].oprc &&
              childProducts.map((pro, i) => (
                <div
                  className="promoText"
                  style={i === 0 ? { paddingTop: '0px' } : {}}
                >
                  <p className="productText">{pro.name}</p>
                  <p className="productText">
                    <span>{pro.rprc}</span>
                  </p>
                </div>
              ))}
            {childProducts &&
              childProducts.length &&
              !childProducts[0].pprc &&
              childProducts[0].rprc &&
              childProducts[0].oprc &&
              childProducts.map((pro, i) => (
                <div
                  className="promoText"
                  style={i === 0 ? { paddingTop: '0px' } : {}}
                >
                  <p className="productText">{pro.name}</p>
                  <p className="productText">
                    {pro.oprc ? (
                      <span>
                        <span
                          style={
                            isTalonOneGp
                              ? { color: '#b30015' }
                              : { color: '#b30015' }
                          }
                        >
                          {pro.rprc}
                        </span>
                        <span
                          className={
                            isTalonOneGp ? 'promoRetail' : 'promoRetail'
                          }
                          style={{
                            paddingLeft: '5px',
                          }}
                        >
                          {pro.oprc}
                        </span>
                        <br />
                      </span>
                    ) : (
                      <span>
                        <span>{pro.rprc}</span>
                        <br />
                      </span>
                    )}
                  </p>
                </div>
              ))}
          </h2>
          {childProducts && childProducts.length && childCount - 3 > 0 && (
            <div>
              <div>{`+ ${childCount - 3} More items`}</div>
              <div>From: {getMinChildPrice(childProducts)}</div>
            </div>
          )}
        </Fragment>
      );
    }

    function renderProductNameAndPriceAndPromotionsForNonGroups() {
      return (
        <Fragment>
          <ProductNameAndPrice
            name={name}
            retailPrice={retailPrice}
            originalPrice={originalPrice}
            productComponentRedesign={productComponentRedesign}
            isTalonOne={isTalonOne}
          />
          {isFuturePromo &&
          showPromoPreview === 'true' &&
          promotionalText &&
          promotionalText.toLowerCase().includes('future') ? (
            <Promotions
              promotions={[promotionalText]}
              promotionalPrice={promotionalPrice}
              isTalonOne={isTalonOne}
            />
          ) : (
            <span />
          )}
          {promotionalText &&
          !promotionalText.toLowerCase().includes('future') ? (
            <Promotions
              promotions={[promotionalText]}
              promotionalPrice={promotionalPrice}
              isTalonOne={isTalonOne}
            />
          ) : (
            <span />
          )}
          {metaProd()}
        </Fragment>
      );
    }

    function renderProductNameAndPriceAndPromotionsToggleComponent() {
      return isGroup ? (
        childProducts.map((product, index) => {
          return (
            <Fragment key={index + product.id}>
              <ProductNameAndPrice
                name={product.name}
                retailPrice={product.rprc}
                originalPrice={product.oprc}
                productComponentRedesign={productComponentRedesign}
              />
              <Promotions
                promotions={[product.promoTxt]}
                promotionalPrice={promotionalPrice}
              />
            </Fragment>
          );
        })
      ) : (
        <Fragment>
          <ProductNameAndPrice
            name={name}
            retailPrice={retailPrice}
            originalPrice={originalPrice}
            productComponentRedesign={productComponentRedesign}
          />
          <Promotions
            promotions={[promotionalText]}
            promotionalPrice={promotionalPrice}
          />
          {metaProd()}
        </Fragment>
      );
    }

    function renderProductNameAndPriceAndPromotions() {
      if (moveProductTogglesToList) {
        if (isGroup) {
          return renderProductNameAndPriceAndPromotionsForGroups();
        } else {
          return renderProductNameAndPriceAndPromotionsForNonGroups();
        }
      } else {
        return renderProductNameAndPriceAndPromotionsToggleComponent();
      }
    }

    // function metaChildProd(product) {
    //   if (!isEmpty(productMetadata)) {
    //     const childProductMetadata = find(productMetadata, ['id', product.id]);
    //     const groupInfo = find(productMetadata, ['id', id]);
    //     if (childProductMetadata) {
    //       return (
    //         <ProductMetadata
    //           productMetadata={childProductMetadata}
    //           groupInfo={groupInfo}
    //           productMetadataStatus={productMetadataStatus}
    //         />
    //       );
    //     }
    //     return null;
    //   }
    //   return null;
    // }

    function metaProd() {
      if (!isEmpty(productMetadata)) {
        const productMetadataInfo = find(productMetadata, ['id', id]);
        if (productMetadataInfo) {
          return (
            <ProductMetadata
              productMetadata={productMetadataInfo}
              groupInfo={{}}
              productMetadataStatus={productMetadataStatus}
            />
          );
        }
        return null;
      }
      return null;
    }

    const renderDesignerName = () => {
      if (!productFlags.isEditorial) {
        if (designer.length !== 0) {
          return (
            <h2 className="product__description seo-wrapper designer-name inherit-font-size">
              {designer}
            </h2>
          );
        }
      }
      return null;
    };

    const renderProductFlags = flagText => {
      if (flagText.indexOf('Colors') === -1) {
        return (
          <div className="gift-badge">
            <span>{flagText.toUpperCase()}</span>
          </div>
        );
      }
      return null;
    };

    const renderGiftBagde = () => {
      return (
        <div className="gift-badge">
          <span className="gift-badge-flag">{this.props.flag}</span>
        </div>
      );
    };
    const renderESGBagde = () => {
      return (
        <div className="esg-badge">
          <span className="esg-badge-flag">Conscious Curation</span>
        </div>
      );
    };

    const renderProductBadge = () => {
      const { flag, flagType } = this.props;
      if (flag && flagType) {
        if (flagType === 'Gift Badge') {
          return renderGiftBagde();
        } else if (flagType === 'Monogram' && esgPLPToggle && showESGLabel) {
          return renderESGBagde();
        } else {
          return renderProductFlags(flag);
        }
      } else if (esgPLPToggle && showESGLabel) {
        return renderESGBagde();
      }
      return null;
    };

    function customClass(count, page, isMobile, hasCurrated, groupType, isAlt) {
      let imageTypeForDesktopTablet;
      let imageTypeForMobile;
      let customClassDesktop = '';
      let customClassMobile = '';
      let commonClass = 'custom-4-desktop-tablet custom-6-mobile'; // "is-one-fifth";
      let desktop2UpPage1 = [6, 32, 46, 72, 86, 112];
      const mobile1UpPage1 = [
        2,
        7,
        14,
        19,
        26,
        31,
        38,
        43,
        50,
        55,
        62,
        67,
        74,
        79,
        86,
        91,
        98,
        103,
        110,
        115,
      ];
      let desktop3UpPage1Large = [17, 57, 97];
      let desktop3UpPage1Small = [18, 19, 58, 59, 98, 99];
      const desktop2UpPage2 = [12, 32, 52, 72, 92, 112];
      const mobile1UpPage2 = [8, 17, 28, 37, 48, 57, 68, 77, 88, 97, 108, 117];

      const deskTop3UpCurrated = [6, 7];
      let desktop1Up = [];
      let desktop2UpPage1Type2 = [];

      if (!isMobile) {
        if (groupType === GROUP_TYPE.GROUP_A) {
          desktop3UpPage1Large = [2, 38];
          desktop3UpPage1Small = [3, 4, 39, 40];
          desktop2UpPage1 = [0, 25];
          desktop1Up = [14, 27];
          desktop2UpPage1Type2 = [15, 37, 50];
        } else if (groupType === GROUP_TYPE.GROUP_B) {
          desktop3UpPage1Large = [0, 24];
          desktop3UpPage1Small = [1, 2, 25, 26];
          desktop2UpPage1 = [36, 39, 55];
          desktop1Up = [9, 16, 38, 48];
          desktop2UpPage1Type2 = [17, 47];
        }
      }

      if (page === 0) {
        // 1st page
        if (desktop2UpPage1.indexOf(count) !== -1) {
          // 2up for desktop
          customClassDesktop += ' is-large-2up-desktop-tablet';
          imageTypeForDesktopTablet = 'large';
        }
        if (desktop3UpPage1Large.indexOf(count) !== -1) {
          // 3up for desktop Large
          customClassDesktop = ' is-large-3up-desktop-tablet';
          commonClass = ' custom-6-desktop-tablet';
          imageTypeForDesktopTablet = 'large';
        }
        if (desktop3UpPage1Small.indexOf(count) !== -1 && !isMobile) {
          // 3up for desktop small
          commonClass = '';
          customClassDesktop = ' custom-8-desktop-tablet';
        }
        if (mobile1UpPage1.indexOf(count) !== -1) {
          // 1up for mobile
          customClassMobile += ' is-large-1up-mobile';
          imageTypeForMobile = 'large';
        }
        if (desktop1Up.indexOf(count) !== -1) {
          customClassDesktop += ' is-large-1up-desktop';
          imageTypeForMobile = 'large';
        }
        if (desktop2UpPage1Type2.indexOf(count) !== -1 && !isAlt) {
          customClassDesktop += ' is-small-2up-desktop-tablet-type2';
        }
        if (
          hasCurrated &&
          !isMobile &&
          deskTop3UpCurrated.indexOf(count) !== -1
        ) {
          commonClass = '';
          customClassDesktop = '';
        }
      } else {
        // Page 2 onwards
        if (desktop2UpPage2.indexOf(count) !== -1) {
          // 2up for desktop
          customClassDesktop += ' is-large-2up-desktop-tablet';
        }
        if (mobile1UpPage2.indexOf(count) !== -1) {
          // 1up for mobile
          customClassMobile += ' is-large-1up-mobile';
        }
      }
      return {
        className: `${commonClass} ${customClassDesktop} ${customClassMobile}`,
        imageTypeForDesktopTablet: imageTypeForDesktopTablet || 'normal',
        imageTypeForMobile: imageTypeForMobile || 'normal',
      };
    }

    let productClass = '';
    let imageTypeForDesktopTablet;
    let imageTypeForMobile;
    if (
      !this.props.filtersSelected &&
      isPLP &&
      [
        TEMPLATE_TYPE.standard,
        TEMPLATE_TYPE.enhancedPLPTemplate1,
        TEMPLATE_TYPE.enhancedPLPTemplate2,
      ].indexOf(templateType) !== -1
    ) {
      const tempObject = customClass(
        this.props.index,
        this.props.page,
        this.props.isMobileDevice,
        this.props.hasCurrated,
        this.props.groupType,
        this.props.isAlt,
      );
      if (this.state.superGridMode === SUPER_GRID_5) {
        productClass = `is-5up-desktop-tablet custom-6-mobile product`;
      } else {
        productClass = `column product ${tempObject.className} basic-grid`;
      }

      imageTypeForDesktopTablet = tempObject.imageTypeForDesktopTablet;
      imageTypeForMobile = tempObject.imageTypeForMobile;

      // customClass(position, this.props.page, this.props.isMobileDevice);
    } else if (
      (isPLP && templateType === TEMPLATE_TYPE.basic5up) ||
      this.state.superGridMode === SUPER_GRID_5
    ) {
      productClass =
        this.state.superGridMode === SUPER_GRID_5
          ? `is-5up-desktop-tablet custom-6-mobile product`
          : `is-5up-desktop-tablet custom-6-mobile product basic-grid`;
      //  ToDo
    } else {
      productClass = `column custom-${
        p4LayoutToggleEnabled ? '3' : '4'
      }-desktop-tablet custom-6-mobile product basic-grid ${(p4LayoutToggleEnabled &&
        'p4Layout') ||
        ''}`;
      // productClass = 'column is-one-fifth product';
    }
    const { isPromoTile, soldOutToggle, displayable } = this.props;
    if (
      isPromoTile &&
      this.state.superGridMode !== SUPER_GRID_5 &&
      page === 0
    ) {
      productClass += ' cms-grid-item';
    }
    return (
      <div
        className={productClass}
        id={this.props.id}
        data-product-source={this.props.source}
        onClick={this.props.clickTracker}
      >
        <ProductLink
          className={classnames({
            product__image:
              plpAspectRatio || searchFlowAspectRatio || favoriteFlow,
            product__image__modern:
              (!isNewAspectRatioCategory && isPLP) ||
              (!srpImgAspectRatio2to3 && searchFlow),
            product2to3__image:
              (isNewAspectRatioCategory && isPLP) ||
              (srpImgAspectRatio2to3 && searchFlow),
          })}
          to={{ pathname: productUrl, query: { ...query } }}
          colorOptions={colorOptions}
          index={this.state.selectedColorSwatchIndex}
          dispatch={this.props.dispatch}
          clickTrackingUrl={this.props.clickTrackingUrl}
          rrApiClickSuppressAddnlHeaders={
            this.props.rrApiClickSuppressAddnlHeaders
          }
          favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
        >
          {renderThumbnail(
            this.props.index,
            isCloudinary,
            imageTypeForDesktopTablet,
            imageTypeForMobile,
            // url,
          )}
          {!this.props.displayable && (
            <div className="product__image__soldOut">{SOLD_OUT_TEXT}</div>
          )}
          {/* {this.getPromotionsWithDogEar(promotions) &&
            <div className="product__dogEar">
              <img
                src={DOG_EAR_IMAGE} alt="Promotion"
                style={this.getStyleForDogEar(this.getPromotionsWithDogEar(promotions))}
              />
            </div>
          } */}
          {!this.props.displayable && (
            <div className="product__image__soldOut">{SOLD_OUT_TEXT}</div>
          )}
          {soldOutToggle && !displayable && (
            <div className="sold-out">{SOLD_OUT_TEXT}</div>
          )}
        </ProductLink>
        {this.state.superGridMode !== SUPER_GRID_5 && (
          <div className="product__details">
            <div
              className={classnames('product__utility', {
                'collapse-empty-space': favoritesQuickLookNewLayout,
              })}
            >
              {/* {(favoriteToggle || favoriteFlow) && this.props.displayable && (
              <FavoriteButton
                productId={this.props.id}
                isFavorite={this.props.isFavorite}
                isDisplayAsGroups={this.props.isDisplayAsGroups}
                cmosItem={this.props.cmosItem}
                cmosCatalogId={this.props.cmosCatalogId}
                pimStyle={this.props.pimStyle}
                quickLookToggle={quickLookToggle}
                favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
              />
            )} */}
            </div>

            <ProductLink
              className="product__link"
              to={{ pathname: productUrl, query: { ...query } }}
              colorOptions={colorOptions}
              index={this.state.selectedColorSwatchIndex}
              dispatch={this.props.dispatch}
              clickTrackingUrl={this.props.clickTrackingUrl}
              rrApiClickSuppressAddnlHeaders={
                this.props.rrApiClickSuppressAddnlHeaders
              }
            >
              {renderDesignerName()}
              {renderProductNameAndPriceAndPromotions()}
              {hasMoreItems && !isGroup ? (
                <span className="moreItems">+ More Items</span>
              ) : (
                ''
              )}
              {this.getDiscount(applicableDiscount)}
              {onlyXItemsLeftStockLevel && (
                <div className="product__onlyXLeft">
                  Only {onlyXItemsLeftStockLevel} Left!
                </div>
              )}
              <div className="product__vertical-space" />
              {(this.props.flag && this.props.flagType) ||
              (esgPLPToggle && showESGLabel) ? (
                <>
                  <div className="product-badges">
                    <div className="bucket-2-badge">{renderProductBadge()}</div>
                  </div>
                  <Badges
                    promotions={{
                      promotionWithoutPrice: promotions.map(one => ({
                        templateHTML: one,
                      })),
                      field: 'templateHTML',
                    }}
                  />
                </>
              ) : (
                <Badges
                  preOrder={this.props.preOrder}
                  newMarkDown={this.props.newMarkDown}
                  isNewArrival={productFlags.isNewArrival}
                  isExclusivelyOurs={productFlags.isOnlyAtNM}
                  promotions={{
                    promotionWithoutPrice: promotions.map(one => ({
                      templateHTML: one,
                    })),
                    field: 'templateHTML',
                  }}
                />
              )}
              {/* <Flags
              preOrder={this.props.preOrder}
              flags={productFlags}
              isNewArrivalCategory={this.props.isNewArrivalCategory}
              colorOptions={colorOptions}
              isValidDisplayableSwatch={this.isValidDisplayableSwatch(
                colorOptions,
              )}
              isGroup={this.props.isGroup}
              singleProductFlagToggle={this.props.singleProductFlagToggle}
              promotions={promotionWithoutPrice}
            /> */}
              {/* <Promotions promotions={promotionWithoutPrice} /> */}
            </ProductLink>

            {this.isSuperGridFive() && (
              <div className="product__swatches">
                {this.props.isColorSwatchOn &&
                  this.props.displayable &&
                  (this.props.swatchOptimizationToggle === true ? (
                    <InView triggerOnce>
                      {({ inView, ref }) => (
                        <div ref={ref}>
                          {inView ? (
                            <ColorSwatches
                              changeSelectedColorSwatchIndex={
                                this.handleOnClickForColorSwatch
                              }
                              selectedColorSwatchIndex={
                                this.state.selectedColorSwatchIndex
                              }
                              colorOptions={colorOptions}
                              url={productUrl}
                              onMouseOverHandler={this.handleMouseOverForSwatch}
                              onMouseOutHandler={this.handleMouseOutForSwatch}
                              errorMessage={errorMessage}
                              emptyImageUrlHandler={this.getValidImageUrl}
                              plpImageSwatchLazyLoadBeta={
                                plpImageSwatchLazyLoadBeta
                              }
                              moveProductTogglesToList={
                                moveProductTogglesToList
                              }
                              productId={this.props.id}
                              updateSwatchesValue={
                                this.props.updateSwatchesValue
                              }
                              hideSwatchOnErrorToggle={
                                this.props.hideSwatchOnErrorToggle
                              }
                              swatchOptimizationToggle={
                                this.props.swatchOptimizationToggle
                              }
                              isPlp
                              productComponentRedesign={false}
                            />
                          ) : null}
                        </div>
                      )}
                    </InView>
                  ) : (
                    <ColorSwatches
                      changeSelectedColorSwatchIndex={
                        this.handleOnClickForColorSwatch
                      }
                      selectedColorSwatchIndex={
                        this.state.selectedColorSwatchIndex
                      }
                      colorOptions={colorOptions}
                      url={productUrl}
                      onMouseOverHandler={this.handleMouseOverForSwatch}
                      onMouseOutHandler={this.handleMouseOutForSwatch}
                      errorMessage={errorMessage}
                      emptyImageUrlHandler={this.getValidImageUrl}
                      plpImageSwatchLazyLoadBeta={plpImageSwatchLazyLoadBeta}
                      moveProductTogglesToList={moveProductTogglesToList}
                      productId={this.props.id}
                      updateSwatchesValue={this.props.updateSwatchesValue}
                      hideSwatchOnErrorToggle={
                        this.props.hideSwatchOnErrorToggle
                      }
                      swatchOptimizationToggle={
                        this.props.swatchOptimizationToggle
                      }
                      productComponentRedesign={false}
                      isPlp
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isTalonOne: state.toggles.TALON_1_SNB,
  isTalonOneGp: state.toggles.TALON_1_SNB_GP,
  isFuturePromo: state.toggles.PROMO_PREVIEW,
  showPromoPreview: get(state.cookies, 'enablePromoPreview', false),
  rrApiClickSuppressAddnlHeaders: get(
    state.toggles,
    'RR_APICLICK_SUPPRESS_ADDITIONAL_HEADERS',
    false,
  ),
  hideSwatchOnErrorToggle: state.toggles.HIDE_SWATCH_ON_ERROR,
  isColorSwatchOn: true, // state.toggles.SRP_COLOR_SWATCH,
  swatchOptimizationToggle: state.toggles.SWATCH_OPTIMIZATION,
  favoritesQuickLookNewLayout: get(
    state,
    'toggles.FAVORITES_QUICK_LOOK_NEW_LAYOUT',
    false,
  ),
  singleProductFlagToggle: get(state.toggles, 'SINGLE_PRODUCT_FLAG', false),
  productComponentRedesign: state.toggles.PRODUCT_COMPONENT_REDESIGN,
  srpImgAspectRatio2to3: state.toggles.SRP_ASPECT_RATIO_2_TO_3,
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
  viewTypeVisibility: get(state, 'productListPage.viewType', 'N/A'),
  plpConfigurator: get(state, 'plpConfigurator', {}),
  parentCategoryId: get(
    state,
    'templates.templateDetails.defaultPath',
    '',
  ).split('_')[1],
  soldOutToggle: state?.toggles?.SOLD_OUT_PLP,
  quickViewPLP: get(state, 'toggles.QUICK_VIEW_PLP', false),
  pathname: state?.page?.location?.pathname,
  p4LayoutToggleEnabled:
    get(state, 'toggles.P4_LAYOUT', false) &&
    get(state, `abTestsOpt.tl387.variation`, 'a') === 'b',
  esgPLPToggle: get(state, 'toggles.ESG_PLP', false),
});

const mapDispatchToProps = {
  setSelectedColorCode,
  showQLModalWindow,
  updateSwatchesValue,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Product),
);
