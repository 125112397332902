import React, { useEffect, useRef } from 'react';
import { Drawer } from '@material-ui/core';
import { BottomSheetContainer, PullHandle } from './BottomSheet.styles';

export default function BottomSheet({
  isBottomSheetOpen,
  setIsBottomSheetOpen,
  scrollY,
  child,
}) {
  const touchStartRef = useRef(null);
  const touchCurrentRef = useRef(null);

  // Prevent background scroll
  useEffect(() => {
    const preventScroll = e => e.preventDefault();

    if (isBottomSheetOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', preventScroll, { passive: false });
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', preventScroll);
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', preventScroll);
    };
  }, [isBottomSheetOpen]);

  // Pull down close
  const handleTouchStart = e => {
    touchStartRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchMove = e => {
    touchCurrentRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchEnd = () => {
    if (touchStartRef?.current && touchCurrentRef?.current) {
      const swipeDistance = touchCurrentRef?.current - touchStartRef?.current;
      if (swipeDistance > 50) {
        setIsBottomSheetOpen();
      }
    }
    touchStartRef.current = null;
    touchCurrentRef.current = null;
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={isBottomSheetOpen}
        onClose={setIsBottomSheetOpen}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      >
        <BottomSheetContainer>
          <PullHandle
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          />
          {child}
        </BottomSheetContainer>
      </Drawer>
    </>
  );
}
