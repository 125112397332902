/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { bool, object, string } from 'prop-types';
import VisualNav from '../visualNav/visualNav';
import ImageAsset from '../imageAsset/imageAsset';
import ImageWithText from '../imageWithText/imageWithText';
import ButtonsGrid from '../buttonsGrid/buttonsGrid';
import SimpleText from '../../../contentful/components/SimpleText/simpleText';
import Carousel from '../carousel/carousel';
import ProductComponent from '../productComponent/productComponent';
import ProductCarouselAsset from '../product/productCarousel';
import RichText from '../richText/richText';
import TextOverImage from '../textOverImage/textOverImage';
import VideoAsset from '../video/video';
import ContentFrame from '../contentFrame/contentFrame';
import { defaultTrackingTags } from '../../constants/constants';
import { PAGE_ID_PLP, PAGE_ID_SRP } from '../../../common/actions/actions-page';

const Default = () => <div />;

const contentComponentMap = {
  frame: ContentFrame,
  visualNav: VisualNav,
  imageContentAsset: ImageAsset,
  carouselComponent: Carousel,
  productComponent: ProductComponent,
  richTextContentAsset: RichText,
  textOverImageContentAsset: TextOverImage,
  textWithImageContentAsset: ImageWithText,
  productCarouselContentAsset: ProductCarouselAsset,
  videoContentAsset: VideoAsset,
  buttonsGrid: ButtonsGrid,
  simpleTextContentAsset: SimpleText,
};

/**
 * @function ContentItem
 * @param {Object} props.cmsContentItem
 * @param {Object} props.parentTrackingTags
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @returns {React.ReactElement}
 */
const ContentItem = ({
  cmsContentItem = {},
  parentTrackingTags,
  placement,
  sessionExpired,
  validPromo,
  newVNToggle,
  newVNToggleSRP,
  pageId,
  templateType,
  totalItems,
  productsList,
  r2Toggle,
}) => {
  const cmsContentId = get(cmsContentItem, 'sys.id');
  const isPromoItem =
    get(cmsContentItem, 'fields.tags.fields.custom') === 'LoggedInPromo';
  const promoEligible = validPromo && !sessionExpired;

  if (isPromoItem && !promoEligible) return <Default />;

  const isLSSAndEDT = () => {
    return (
      (templateType === 'LSS' ||
        templateType === 'EDT' ||
        templateType === 'Editorial' ||
        totalItems === 0 ||
        isEmpty(productsList)) &&
      pageId !== PAGE_ID_SRP &&
      r2Toggle
    );
  };

  const renderVNComponent =
    (pageId === PAGE_ID_PLP && newVNToggle) ||
    (pageId === PAGE_ID_SRP && newVNToggleSRP);

  if (!isLSSAndEDT()) {
    if (cmsContentItem.contentType === 'visualNav' && !renderVNComponent)
      return <Default />;
  }

  const componentProps = {
    cmsContentItem,
    parentTrackingTags,
    placement,
    isLSSAndEDT: isLSSAndEDT(),
  };

  const Component = contentComponentMap[cmsContentItem.contentType] || Default;

  return <Component {...componentProps} key={cmsContentId} />;
};

ContentItem.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

ContentItem.propTypes = {
  cmsContentItem: object,
  parentTrackingTags: object,
  placement: string,
  sessionExpired: bool,
  validPromo: bool,
};

const mapStateToProps = state => ({
  newVNToggle: get(state, 'toggles.NEW_PLP_VIS_NAV', false),
  newVNToggleSRP: state?.toggles?.NEW_SRP_VIS_NAV,
  pageId: state?.page?.pageId,
  templateType: get(state, 'productListPage.products.templateType', 'P3'),
  totalItems: get(state, 'productListPage.products.total', ''),
  productsList: get(state, 'productListPage.products.list', []),
  r2Toggle: state.toggles.BG_PHASE_2_RELEASE_2,
});

export default connect(mapStateToProps)(ContentItem);
