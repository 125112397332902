import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import isElement from 'lodash/isElement';
import WithTappable from 'client/common/components/Tappable/Tappable';
import Slider from 'react-slick';
import startCase from 'lodash/startCase';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import { isIpadPro } from 'client-utils/utilities-page';
import WordWrap from 'pdp/components/ProductPage/components/WordWrap';
import Image from 'pdp/components/ProductPage/components/Image/image';
import CustomDropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/CustomDropDownComponent/customDropDownComponent';
import './productOptionSet.scss';

export default class ProductOptionSet extends React.Component {
  constructor() {
    super();
    this.state = {
      triggerAnalytics: true,
    };
    this.showSlides = this.showSlides.bind(this);
    this.hideSlides = this.hideSlides.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    if (this.props.enableCarousel) {
      setImmediate(() => {
        this.handleRefactor();
        this.hideSlides();
        this.showSlides();
      });
    }
  }

  getDisplayText(hovered = {}, selected = {}) {
    if (this.props.optionType !== 'PDP') {
      if (hovered.name || selected.name) {
        return `${
          hovered.name
            ? hovered.name.toLowerCase()
            : selected.name.toLowerCase()
        }`;
      } else {
        return '';
      }
    } else if (hovered.displayName || selected.displayName) {
      return `${
        hovered.displayName
          ? hovered.displayName
              .replace(/[0-9]+/g, '')
              .trim()
              .toLowerCase()
          : selected.displayName
              .replace(/[0-9]+/g, '')
              .trim()
              .toLowerCase()
      }`;
    } else {
      return '';
    }
  }

  getTooltipText(availableSkus, selectedIndex) {
    let text = '';
    if (!availableSkus || !availableSkus.length) return text;
    const sku = availableSkus[selectedIndex];
    if (sku) {
      if (!sku.sellable) return 'Out Of Stock';
      else if (sku.dropShip) return text;
      else if (sku.preOrder) {
        text = 'Pre Order';
      } else if (sku.backOrder) {
        text = 'Back Order';
      } else if (!sku.inStock) {
        text = 'Out Of Stock';
      }
    } else {
      text = 'Out Of Stock';
    }
    return text;
  }

  isSizeOption(label) {
    return label === 'size';
  }

  showSlides() {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      activeSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '0');
      });
    }
  }

  hideSlides() {
    const container = document.getElementById(this.props.id);
    let hiddenSlides;
    if (container) {
      hiddenSlides = Array.from(
        container.querySelectorAll('.slick-slide button'),
      );
      hiddenSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '-1');
      });
    }
  }

  handleFocus(prev, next) {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      if (prev < next) {
        activeSlides[activeSlides.length - 1].focus();
      } else if (prev > next) {
        activeSlides[0].focus();
      }
    }
  }

  handleRefactor() {
    const container = document.getElementById(this.props.id);
    const modal = document.querySelectorAll('.nm-modal__window')[0];

    if (container && modal) {
      const prevArrow = container.querySelector('.slick-prev');
      const close = modal.querySelector('.close');
      handleTabEventWith(prevArrow, close);
    }
  }

  render() {
    const onMouseOver = e => {
      if (this.props.isDesktop && !isIpadPro()) {
        this.props.onHover(
          parseInt(e.currentTarget.getAttribute('data-index'), 10),
          this.props.label,
        );
      }
    };
    const onMouseOut = () => {
      if (this.props.isDesktop && !isIpadPro()) {
        this.props.onHover(-1, this.props.label);
      }
    };
    const getOptionListItemClasses = value =>
      this.props.optionType !== 'PDP'
        ? classnames({
            'product-options__list__item': true,
            'product-options__list__swatch-item ':
              !!value.url || !this.props.hasColorMedia,
          })
        : classnames({
            'product-options__list__item': true,
          });

    /* eslint-disable no-unused-vars */
    const {
      availableSkus,
      enableCarousel,
      id,
      label,
      noAvailableSizeSelected,
      onClick,
      options,
      selectedIndex,
      sizeGuide,
      isColorTextDropdownEnabled,
      productCategory,
      isDesktop,
      isSkuMessage,
    } = this.props;
    /* eslint-enable no-unused-vars */

    const { hoveredIndex } = this.props;

    const colorNames = options.map(a => a.name);
    const colorImageSwatchCarouselSettings = {
      beforeChange: (oldIndex, newIndex) => {
        this.hideSlides();
        setTimeout(() => {
          this.handleFocus(oldIndex, newIndex);
        }, 500);
      },
      afterChange: this.showSlides,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 470,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };

    const colorNameSwatchCarouselSettings = {
      beforeChange: (oldIndex, newIndex) => {
        this.hideSlides();
        setTimeout(() => {
          this.handleFocus(oldIndex, newIndex);
        }, 500);
      },
      afterChange: this.showSlides,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 470,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 342,
          settings: {
            slidesToShow: 2.5,
          },
        },
      ],
    };

    let carouselDefaultSettings;
    if (enableCarousel) {
      carouselDefaultSettings = colorImageSwatchCarouselSettings;
      if (!options[0].url) {
        carouselDefaultSettings = colorNameSwatchCarouselSettings;
      }
    }

    const isDisabled = index => {
      if (noAvailableSizeSelected) {
        return noAvailableSizeSelected;
      }
      if (!availableSkus || !availableSkus.length) {
        return false;
      }
      if (!availableSkus[index]) {
        return true;
      }
      return !get(availableSkus[index], 'sellable');
    };

    const setAlert = (value, label, srAlert) => {
      if (isElement(srAlert)) {
        srAlert.textContent =
          this.props.optionType !== 'PDP'
            ? `${value.name} ${label} selected`
            : `${value.displayName
                .replace(/[0-9]+/g, '')
                .trim()} ${label} selected`;
      }
    };

    const handleAnalytics = () => {
      if (window && window.utag && window.utag.link && window.utag_data_dt) {
        const data = {
          ...window.utag_data,
          product_interaction: 'color selection child',
          event_name: 'colorSelectionChild',
        };
        window.utag.link(data);
      }
      this.setState({ triggerAnalytics: true });
    };

    const getOnTouchTapHandler = (index, e, value) => {
      if (this.props.page === 'PDP') {
        this.props.updateColorState();
      }
      if (!this.state.triggerAnalytics) {
        handleAnalytics();
      }
      e.preventDefault();
      onClick(index, label);
      setAlert(value, label, document.getElementById('screenReaderAlert'));
    };
    const getOptionButtonClasses = (value, index) => {
      const isBeautyDropdownEnabled =
        isColorTextDropdownEnabled && productCategory === 'Beauty';
      const checkSwatchOnHover =
        !isBeautyDropdownEnabled || hoveredIndex === -1;
      return this.props.optionType !== 'PDP'
        ? classnames({
            'product-options__button': true,
            'product-options__button--active':
              index === selectedIndex && !value.url,
            'product-options__button--swatch':
              !!value.url || !this.props.hasColorMedia,
            'product-options__button--swatch--active':
              (index === selectedIndex && !!value.url && checkSwatchOnHover) ||
              (index === selectedIndex &&
                !this.props.hasColorMedia &&
                checkSwatchOnHover) ||
              (index === hoveredIndex && isBeautyDropdownEnabled),
            'product-options__button--disabled': isDisabled(index),
          })
        : classnames({
            'product-options__button': true,
            'product-options__button--active': index === selectedIndex,
          });
    };
    const getLabel = (index, value, label, selected) => {
      if (isDisabled(index)) {
        return this.props.optionType !== 'PDP'
          ? `${value.name} not available`
          : `${value.displayName.replace(/[0-9]+/g, '').trim()} not available`;
      }
      if (selected) {
        return this.props.optionType !== 'PDP'
          ? `${value.name} ${label} selected`
          : `${value.displayName
              .replace(/[0-9]+/g, '')
              .trim()} ${label} selected`;
      } else {
        return this.props.optionType !== 'PDP'
          ? `${value.name} ${label}`
          : `${value.displayName.replace(/[0-9]+/g, '').trim()} ${label}`;
      }
    };

    if (options.length === 0) return false;

    const getOptionForNonPDP = value => {
      // A fallback fix to handle swatches when there are colors, but no corrsponding media.
      // Displays the NA image in such cases. Using the PROD version of swatch_NA.
      // BGO-11944
      if (this.props.hasColorMedia === false) {
        return (
          <Image
            alt={value.name}
            data-color-key={value.key}
            data-color-name={value.name}
            errorImage="/category/product/swatch_NA.jpg"
            title={value.name}
          />
        );
      }
      const SWATCH_RESIZE_URL = value.url
        ? value.url.replace('/f_auto,q_auto', `/f_auto,q_auto,dpr_2.0,w_20`)
        : value.url;
      return !value.url ? (
        <Image
          alt={value.name}
          data-color-key={value.key}
          data-color-name={value.name}
          errorImage={value.errorUrl}
          title={value.name}
        />
      ) : (
        <Image
          alt={value.name}
          data-color-key={value.key}
          data-color-name={value.name}
          errorImage={value.errorUrl}
          src={SWATCH_RESIZE_URL}
          title={value.name}
          isFromShopTheLook={this.props.isFromShopTheLook}
        />
      );
    };

    const renderColorSwatches = (options, label) => {
      return options.map((value, index) =>
        enableCarousel && value.hideOnMonogramModal ? null : (
          <li key={index} className={getOptionListItemClasses(value)}>
            <WithTappable mouseOver={onMouseOver} mouseOut={onMouseOut}>
              {tapProps => (
                <button
                  aria-label={getLabel(
                    index,
                    value,
                    label,
                    index === selectedIndex,
                  )}
                  className={getOptionButtonClasses(value, index)}
                  data-index={index}
                  onClick={e => getOnTouchTapHandler(index, e, value)}
                  {...tapProps}
                  disabled={value.areAllSkusOOS}
                >
                  {this.props.optionType !== 'PDP' ? (
                    getOptionForNonPDP(value)
                  ) : (
                    <WordWrap
                      content={value.displayName
                        .replace(/[0-9]+/g, '')
                        .trim()
                        .toLowerCase()}
                    />
                  )}
                </button>
              )}
            </WithTappable>
          </li>
        ),
      );
    };
    const isClrDropdownEnabled =
      isColorTextDropdownEnabled && productCategory === 'Beauty';
    return (
      <div id={`product-options__${label}`} className="product-options">
        <div>
          <span className="product-options__heading">
            {this.props.label === 'color' ? (
              <div>
                <span className="product-options__label"> {label}: </span>
                {this.getDisplayText(
                  options[hoveredIndex],
                  options[selectedIndex],
                )}
              </div>
            ) : null}

            {/* {sizeGuide && <div className="product-options__size-guide" dangerouslySetInnerHTML={{ __html: sizeGuide }} />}
            <Toggle feature="TRUEFIT">
              <Toggle
                feature="DISABLE_NMO_UI_EXTERNAL_LIBRARIES"
                fallback={this.isSizeOption(label) && <TrueFit productId={this.props.productId} />}
              >
                {false}
              </Toggle>
            </Toggle> */}
          </span>
        </div>
        <div className="">
          <ul id={id} className="product-options__list">
            {enableCarousel ? (
              <Slider {...carouselDefaultSettings}>
                {renderColorSwatches(options, label)}
              </Slider>
            ) : (
              renderColorSwatches(options, label)
            )}
          </ul>
        </div>
        {isSkuMessage}
        {isClrDropdownEnabled && (
          <CustomDropDownComponent
            {...this.props}
            options={colorNames}
            optionType="color"
            optionSettings={{
              label: 'index_value',
            }}
            selectedValue={startCase(
              this.getDisplayText(
                options[hoveredIndex],
                options[selectedIndex],
              ),
            )}
            handleAnalytics={handleAnalytics}
            mouseOver={onMouseOver}
            mouseOut={onMouseOut}
            name="colorDropdown"
          />
        )}
      </div>
    );
  }
}
